import { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { PrivateRoute } from 'routing/components'
import { InternalLayer, AppLayer } from 'core/layers'
import { Header, Spinner } from 'components/common'
import HomeRouting, { routes as homeRoutes } from 'modules/Home/routing'
import UsersRouting, { routes as usersRoutes } from 'modules/UserManagement/routing'
import ManageWidgetsRouting, { routes as widgetsRoutes } from 'modules/ManageWigets/routing'
import GraphsRouting, { routes as graphsRoutes } from 'modules/Graphs/routing'
import WeatherRouting, { routes as weatherRoutes } from 'modules/Weather/routing'
import CamerasRouting, { routes as camerasRoutes } from 'modules/AdvancedInfo/routing'

const PrivateRouting: React.FC = () => (
  <AppLayer>
    <Suspense fallback={<Spinner />}>
      <Header />
      <div className='wrap'>
        <Switch>
          <PrivateRoute path={homeRoutes.root} component={HomeRouting} />
          <PrivateRoute path={usersRoutes.root} component={UsersRouting} />
          <PrivateRoute path={widgetsRoutes.root} component={ManageWidgetsRouting} />
          <PrivateRoute path={graphsRoutes.root} component={GraphsRouting} />
          <PrivateRoute path={weatherRoutes.root} component={WeatherRouting} />
          <PrivateRoute path={camerasRoutes.root} component={CamerasRouting} />
          <Redirect path='*' to={homeRoutes.root} />
        </Switch>
      </div>
    </Suspense>
  </AppLayer>
)

const InternalRoutingLayer: React.FC = () => {
  return (
    <InternalLayer>
      <PrivateRouting />
    </InternalLayer>
  )
}

export default InternalRoutingLayer
