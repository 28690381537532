const base = '/auth'

const routes = {
  root: base,
  login: `${base}/login`,
  registration: `${base}/registration`,
  forgot: `${base}/forgot`,
  recoverPassword: `${base}/reset_password`,
  verifyEmail: `${base}/verify_email`,

}

export default routes
