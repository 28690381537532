import styles from './Header.module.scss'
import { Menu, Dropdown } from 'antd'
import {
  AppstoreOutlined,
  BellOutlined,
  TeamOutlined,
  ApartmentOutlined,
  BarChartOutlined,
  PieChartOutlined,
  DashboardOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  CheckCircleOutlined,
  VideoCameraOutlined,
  EditOutlined,
  ExportOutlined,
  CloudOutlined,
} from '@ant-design/icons'
import logo from '../../../assets/images/5GCT-logo-new.svg'
import { Radio } from 'antd'
import { useDispatch } from 'react-redux'
import { logoutUser } from '../../../core/session/slices/session'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../../../core/contexts'

import { useTranslation } from 'react-i18next'
import { setLanguage } from '../../../modules/Auth/slices/auth'

const Header: React.FC = () => {
  const { i18n } = useTranslation()
  // const [lang, setLang] = useState('en')
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const onLogout = () => {
    dispatch(logoutUser())
  }
  const valueMe = useContext(UserContext)

  function handleChange(value: string) {
    dispatch(setLanguage(value))
    i18n.changeLanguage(value)
  }

  const onChange = (e: any) => {
    handleChange(e.target.value)
  }

  const menu = (
    <Menu>
      <div className={styles.header_profile_name}>{valueMe?.email}</div>
      <Menu.Item key='Edit Profile'>
        <Link to='/users-management/edit-profile'>
          <EditOutlined /> {t('edit_profile_sub_menu')}
        </Link>
      </Menu.Item>
      <Menu.Item key='LogOut' onClick={onLogout}>
        <ExportOutlined /> {t('log_out_sub_menu')}
      </Menu.Item>
    </Menu>
  )

  return (
    <div className={styles.header}>
      <div className={styles.header_logo}>
        <img src={logo} />
        {/*</Link>*/}
      </div>
      <Menu mode='horizontal' defaultSelectedKeys={['mail']}>
        <Menu.Item key='mail' icon={<DashboardOutlined style={{ fontSize: '20px' }} />}>
          <Link to='/dashboard'>{t('dashboard_nav')}</Link>
        </Menu.Item>

        {valueMe?.role !== 'guest' && (
          <Menu.Item key='cameras' icon={<VideoCameraOutlined style={{ fontSize: '20px' }} />}>
            <Link to='/advanced_info/devices'>{t('devices_nav')}</Link>
          </Menu.Item>
        )}
        {valueMe?.role !== 'guest' && (
          <Menu.Item key='covid' icon={<PieChartOutlined style={{ fontSize: '20px' }} />}>
            <Link to='/advanced_info/covid'>{t('covid_nav')}</Link>
          </Menu.Item>
        )}

        <Menu.Item key='weather' icon={<CloudOutlined style={{ fontSize: '20px' }} />}>
          <Link to='/weather'>{t('weather_nav')}</Link>
        </Menu.Item>

        <Menu.Item key='graphs' icon={<BarChartOutlined style={{ fontSize: '20px' }} />}>
          <Link to='/graphs'>{t('graphs_nav')}</Link>
        </Menu.Item>

        {valueMe?.role !== 'guest' && (
          <>
            <Menu.SubMenu
              key='SubMenu2'
              title={t('user_management_nav')}
              icon={<UsergroupAddOutlined style={{ fontSize: '20px' }} />}
            >
              <Menu.ItemGroup>
                <Menu.Item key='Register' icon={<UserAddOutlined />}>
                  <Link to='/users-management/create'>{t('create_sub_menu')}</Link>
                </Menu.Item>
                <Menu.Item key='User List' icon={<TeamOutlined />}>
                  <Link to='/users-management/user-list'>{t('user_list_sub_menu')}</Link>
                </Menu.Item>
                <Menu.Item key='Permissions' icon={<CheckCircleOutlined />}>
                  <Link to='/users-management/permissions'>{t('permissions_sub_menu')}</Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu.SubMenu>
            <Menu.Item
              key='Manage Widgets'
              icon={<AppstoreOutlined style={{ fontSize: '20px' }} />}
            >
              <Link to='/manage-widgets'>{t('manage_widgets_nav')}</Link>
            </Menu.Item>
          </>
        )}
        <Menu.Item key='Api' icon={<ApartmentOutlined />}>
          {t('api_nav')}
        </Menu.Item>
      </Menu>
      <div className={styles.settings_block}>
        <div className={styles.first_group}>
          <div className={styles.reminder}>
            <div className={styles.count}>12</div>
            <BellOutlined style={{ zIndex: '1', fontSize: '25px', color: '#fff' }} />
          </div>

          <Radio.Group onChange={onChange} defaultValue={'en'} size={'small'}>
            <Radio.Button value='en'>EN</Radio.Button>
            <Radio.Button value='th'>TH</Radio.Button>
          </Radio.Group>
        </div>
        <Dropdown overlay={menu} trigger={['click']}>
          <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
            <div className={styles.header_avatar}>
              <div
                className={
                  valueMe?.is_active ? styles.user_sensor_active : styles.user_sensor_inactive
                }
              ></div>
              <div className={styles.avatar_inner}>
                <img src={valueMe?.photo_url} alt='' />
              </div>
            </div>
          </a>
        </Dropdown>
      </div>
    </div>
  )
}

export default Header
