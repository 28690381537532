import { Spinner } from 'components/common'

// Initialization layer
// Common setup for whole Application
const InitializationLayer: React.FC = ({ children }) => {
  if (false) return <Spinner />

  return <>{children}</>
}

export default InitializationLayer
