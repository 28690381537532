import { Suspense, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { Spinner } from 'components/common'
import routes from './routes'

export { routes }

export const Graphs = lazy(() => import(/* webpackChunkName: "Weather" */ 'modules/Graphs/pages/Graphs'))

const GraphsRouting = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route exact path={routes.root} component={Graphs} />
      <Redirect path='*' to={routes.root} />
    </Switch>
  </Suspense>
)

export default GraphsRouting
