import styles from './CommonButton.module.scss'

export type ButtonType = {
  htmlType?: string
  onClick?: any
  background?: string
  height?: string
  border?: string
  fontSize?: string
  width?: string
  padding?: string
}

const CommonButton: React.FC<ButtonType> = props => {
  return (
    <>
      <button
        onClick={props.onClick}
        style={{
          background: `${props.background}`,
          height: `${props.height}`,
          width: `${props.width}`,
          border: `${props.border}`,
          fontSize: `${props.fontSize}`,
          padding: `${props.padding}`,
        }}
        className={styles.button}
      >
        {props.children}
      </button>
    </>
  )
}

export default CommonButton
