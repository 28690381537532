export const SESSION_TOKEN = 'SESSION_TOKEN'
export const CURRENT_LANG = 'i18nextLng'

// class SessionUtils {
//   storeSession(token: string) {
//     localStorage.setItem(SESSION_TOKEN, token)
//   }
//
//   getSessionToken() {
//     return localStorage.getItem(SESSION_TOKEN)
//   }
//
//   clearSession() {
//     localStorage.removeItem(SESSION_TOKEN)
//   }
// }

class SessionUtils {
  storeSession(token: string) {
    localStorage.setItem(SESSION_TOKEN, token)
  }

  getSessionToken() {
    return localStorage.getItem(SESSION_TOKEN)
  }

  clearSession() {
    localStorage.removeItem(SESSION_TOKEN)
  }

  // storeSessionLang(lang: string) {
  //   localStorage.setItem(CURRENT_LANG, lang)
  // }

  // clearSessionLang() {
  //   localStorage.removeItem(CURRENT_LANG)
  // }

  // getSessionLang() {
  //   return localStorage.getItem(CURRENT_LANG)
  // }
}

export default new SessionUtils()
