import { Suspense, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { Spinner } from 'components/common'
import routes from './routes'

export { routes }

export const ManageWidgets = lazy(
  () => import(/* webpackChunkName: "Cameras" */ 'modules/ManageWigets/pages/ManageWidgets')
)
export const WidgetsCreate = lazy(
  () => import(/* webpackChunkName: "WidgetsCreate" */ 'modules/ManageWigets/pages/WidgetsCreate')
)

const ManageWidgetsRouting = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route exact path={routes.root} component={ManageWidgets} />
      <Route exact path={routes.create} component={WidgetsCreate} />
      <Redirect path='*' to={routes.root} />
    </Switch>
  </Suspense>
)

export default ManageWidgetsRouting
