import { Suspense, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { Spinner } from 'components/common'
import routes from './routes'

export { routes }

export const Cameras = lazy(
  () => import(/* webpackChunkName: "Cameras" */ 'modules/AdvancedInfo/pages/Cameras')
)
export const Covid = lazy(
  () => import(/* webpackChunkName: "Covid" */ 'modules/AdvancedInfo/pages/Covid')
)

const CamerasRouting = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route exact path={routes.devices} component={Cameras} />
      <Route exact path={routes.covid} component={Covid} />
      <Redirect path='*' to={routes.root} />
    </Switch>
  </Suspense>
)

export default CamerasRouting
