import { usingGet } from '../../../api/apiHelpers'

export const getAllDevices = () => usingGet('/devices/all')
export const getAllThaiCovidData = (thai: any) =>
  usingGet(
    `/covid/thai_cases?from_date=${
      thai.dateFrom ? thai.dateFrom : '2000-01-01T00%3A00%3A00'
    }&to_date=${thai.dateTo ? thai.dateTo : '2100-01-01T00%3A00%3A00'}`
  )
export const getAllProvinces = () => usingGet('/province/all_provinces_with_covid')
export const getExactProvincesData = (provinceData: any) =>
  usingGet(
    `/covid/province_cases?province=${provinceData.provinceName}&from_date=${
      provinceData.dateFrom ? provinceData.dateFrom : '2000-01-01T00%3A00%3A00'
    }&to_date=${provinceData.dateFrom ? provinceData.dateTo : '2100-01-01T00%3A00%3A00'}`
  )
