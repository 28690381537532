import { Suspense, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { Spinner } from 'components/common'
import routes from './routes'

export { routes }

export const Weather = lazy(
  () => import(/* webpackChunkName: "Weather" */ 'modules/Weather/pages/Weather')
)

const WeatherRouting = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route exact path={routes.root} component={Weather} />
      <Redirect path='*' to={routes.root} />
    </Switch>
  </Suspense>
)

export default WeatherRouting
