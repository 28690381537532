import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { userLoggedOut } from 'core/session/slices/session'
import { RegisterState } from './types'
import { RegisterUserParams } from '../../types'
import { addRegisterUser } from '../../api/usersService'
import { history } from '../../../../store'
import routes from '../../routing/routes'
import { message } from 'antd'

const initialState: RegisterState = {
  registerRequest: {},
}

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(addUser.pending, state => {
        state.registerRequest = { isLoading: true }
      })
      .addCase(addUser.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.registerRequest = { isLoading: false, result: content, error }
      })
      .addCase(userLoggedOut, () => initialState)
  },
})

export const {} = registerSlice.actions
export default registerSlice.reducer

export const addUser = createAsyncThunk(
  'register/addUser',
  async (values: RegisterUserParams, { dispatch }) => {
    const response = await addRegisterUser(values)
    if (response.success) {
      history.push(routes.users)
      message.success('User added successfully', 1)
    }
    if (response.error) {
      message.error(
        `${response.error?.detail[0].msg ? response.error?.detail[0].msg : response.error?.detail}`,
        3
      )
    }

    return response
  }
)
