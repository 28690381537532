import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { userLoggedOut } from 'core/session/slices/session'
import { getDaily, getHourly } from '../../api/weatherService'
import { WeatherState } from './types'

const initialState: WeatherState = {
  dailyRequest: {},
  hourlyRequest: {},
}

const dailyWeatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDailyWeather.pending, state => {
        state.dailyRequest = { isLoading: true }
      })
      .addCase(getDailyWeather.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.dailyRequest = { isLoading: false, result: content, error }
      })
      .addCase(getHourlyWeather.pending, state => {
        state.hourlyRequest = { isLoading: true }
      })
      .addCase(getHourlyWeather.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.hourlyRequest = { isLoading: false, result: content, error }
      })
      .addCase(userLoggedOut, () => initialState)
  },
})

export const {} = dailyWeatherSlice.actions
export default dailyWeatherSlice.reducer

export const getDailyWeather = createAsyncThunk('cameras/getDaily', async (cityValue: string) => {
  const response = await getDaily(cityValue)
  return response
})

export const getHourlyWeather = createAsyncThunk('cameras/getHourly', async (cityValue: string) => {
  const response = await getHourly(cityValue)
  return response
})
