import { ComponentType } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { SessionUtils } from 'utils'
import homeRoutes from 'modules/Home/routing/routes'

type PublicRouteProps = {
  path?: string | string[]
  restricted?: boolean
  component: ComponentType<any>
  exact?: boolean
}

const PublicRoute: React.FC<PublicRouteProps> = props => {
  const { path, component: PublicComponent, restricted = false } = props
  const restrictedAccess = !!SessionUtils.getSessionToken() && restricted

  return (
    <Route
      path={path}
      render={props =>
        restrictedAccess ? (
          <Redirect
            to={{
              pathname: homeRoutes.root,
              state: { target: props.location },
            }}
          />
        ) : (
          <PublicComponent {...props} />
        )
      }
    />
  )
}

export default PublicRoute
