import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userLoggedOut } from 'core/session/slices/session'
import { CamerasState } from './types'
import { getAllDevices } from '../../api/camerasService'

const initialState: CamerasState = {
  camerasList: {},
}

const camerasSlice = createSlice({
  name: 'cameras',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(getDevices.pending, state => {
        state.camerasList = { isLoading: true }
      })
      .addCase(getDevices.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.camerasList = { isLoading: false, result: content, error }
      })

      // Clear store if 'userLoggedOut' happened
      .addCase(userLoggedOut, () => initialState)
  },
})

export const {} = camerasSlice.actions
export default camerasSlice.reducer

// export const getUrl = createAsyncThunk('cameras/getUrl', async (urlValue: string) => {
//   const response = await getPlayerUrl(urlValue)
//   return response
// })
export const getDevices = createAsyncThunk('cameras/getDevices', async () => {
  const response = await getAllDevices()
  return response
})
