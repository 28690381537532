import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userLoggedOut } from 'core/session/slices/session'
import { AuthState } from './types'
import {
  authEdit,
  authLogin,
  authMe,
  authPasswordRecover,
  authRecover,
  authUpdatePhoto,
  authVerifyEmail,
} from '../../api/authService'
import { authRegister } from '../../api/authService'
import {
  EditUserParams,
  LoginUserParams,
  RecoverData,
  RegisterUserParams,
  TokenData,
} from '../../types'
import { history } from 'store'
import { SessionUtils } from 'utils'
import homeRoutes from 'modules/Home/routing/routes'
import { message } from 'antd'
import routes from '../../routing/routes'

const initialState: AuthState = {
  loginRequest: {},
  userLoginRequest: {},
  userData: {},
  recoverData: {},
  changePasswordData: {},
  verifyToken: {},
  language: 'en',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(LoginUser.pending, state => {
        state.loginRequest = { isLoading: true }
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.userData = action.payload
        state.loginRequest = { isLoading: false, result: content, error }
      })

      .addCase(registerUser.pending, state => {
        state.userLoginRequest = { isLoading: true }
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.userLoginRequest = { isLoading: false, result: content, error }
      })

      .addCase(getMe.pending, state => {
        state.userData = { isLoading: true }
      })
      .addCase(getMe.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.userData = { isLoading: false, result: content, error }
      })

      .addCase(editUser.pending, state => {
        state.userData = { isLoading: true }
      })
      .addCase(editUser.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.userData = { isLoading: false, result: content, error }
      })

      .addCase(recoverPassword.pending, state => {
        state.recoverData = { isLoading: true }
      })
      .addCase(recoverPassword.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.recoverData = { isLoading: false, result: content, error }
      })

      .addCase(changePassword.pending, state => {
        state.changePasswordData = { isLoading: true }
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.changePasswordData = { isLoading: false, result: content, error }
      })
      .addCase(verifyEmail.pending, state => {
        state.verifyToken = { isLoading: true }
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.verifyToken = { isLoading: false, result: content, error }
      })

      .addCase(userLoggedOut, () => initialState)
  },
})

export const { setLanguage } = authSlice.actions
export default authSlice.reducer

export const LoginUser = createAsyncThunk('auth/LoginUser', async (formParams: LoginUserParams) => {
  const response = await authLogin(formParams)
  const { content } = response
  console.log('response', response.error)
  if (!content) {
    message.error(response.error?.detail, 4)
  }
  if (content.access_token) {
    SessionUtils.storeSession(content.access_token)
    history.push(homeRoutes.root)
    message.success(`You have successfully logged`, 2)
  }
  return response
})

export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async (userParams: RegisterUserParams) => {
    const response = await authRegister(userParams)
    const { content, success, status } = response
    const responseMessage = response?.error?.detail[0].msg
    const emailMessage = response?.error?.detail
    if (!content) {
      message.error(response.error?.detail, 4)
    }
    if (!success && status === 422) {
      message.error(`${responseMessage}`, 4)
    }
    if (!success && status === 400) {
      message.error(`${emailMessage}`, 4)
    }
    if (content.access_token) {
      history.push(routes.login)
      message.success(`Your account has been created`, 3)
    }
    return response
  }
)

export const getMe = createAsyncThunk('auth/getMe', async () => {
  const response = await authMe()
  // const {content} = response
  return response
})

export const editUser = createAsyncThunk('auth/editUser', async (newUserData: EditUserParams) => {
  console.log('editedit')
  const response = await authEdit(newUserData)
  const { content, error } = response
  console.log('response', response)
  if (content) {
    message.success(`Your data is Saved`, 2)
  }
  if (error) {
    message.error(`${error?.detail}`, 3)
  }
  return response
})

export const updatePhoto = createAsyncThunk('auth/updatePhoto', async (originObj: any) => {
  const response = await authUpdatePhoto(originObj)
  // const {content} = response
  console.log('photo', response)
  return response
})

export const recoverPassword = createAsyncThunk('auth/recoverPassword', async (email: string) => {
  const response = await authRecover(email)
  const { content, error } = response
  if (content) {
    message.success(`${content?.msg}`, 3)
  }
  if (error) {
    message.error(`${error?.detail}`, 3)
  }
  return response
})

export const changePassword = createAsyncThunk('auth/changePassword', async (data: RecoverData) => {
  const response = await authPasswordRecover(data)
  console.log('recover-response', response)
  const { content, error } = response
  if (content) {
    message.success(`${content?.msg}`, 3)
  }
  if (error) {
    message.error(`${error?.detail}`, 3)
  }
  return response
})

export const verifyEmail = createAsyncThunk('auth/verifyEmail', async (data: TokenData) => {
  const response = await authVerifyEmail(data)
  console.log('1111recover-response', response)
  const { content, error } = response
  if (content) {
    history.push(routes.login)
    message.success(`${content?.msg}`, 3)
  }
  if (error) {
    message.error(`${error?.detail}`, 3)
  }
  return response
})
