import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userLoggedOut } from 'core/session/slices/session'

import { HomeState } from './types'
import { getIqAirData } from '../../api/homeService'

const initialState: HomeState = {
  counter: 0,
  iqAirData: {},
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateCounter(state, action: PayloadAction<number>) {
      state.counter += action.payload
    },
    resetCounter(state) {
      state.counter = initialState.counter
    },
  },
  extraReducers: builder => {
    builder

      .addCase(getIqAir.pending, state => {
        state.iqAirData = { isLoading: true }
      })
      .addCase(getIqAir.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.iqAirData = { isLoading: false, result: content, error }
      })

      .addCase(userLoggedOut, () => initialState)
  },
})

export const { updateCounter, resetCounter } = homeSlice.actions
export default homeSlice.reducer

export const getIqAir = createAsyncThunk('home/getIqAir', async () => {
  const response = await getIqAirData()
  return response
})
