import { Suspense, lazy } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { Spinner } from 'components/common'
import routes from './routes'

export { routes }

export const Home = lazy(() => import(/* webpackChunkName: "Home" */ 'modules/Home/pages/Home'))

const HomeRouting = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <Route exact path={routes.root} component={Home} />
      <Redirect path='*' to={routes.root} />
    </Switch>
  </Suspense>
)

export default HomeRouting
