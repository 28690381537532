import { usingGet, usingPost, usingPut } from 'api/apiHelpers'
import { EditUserParams, LoginUserParams, RecoverData, RegisterUserParams, TokenData } from 'modules/Auth/types'

export const authLogin = (formParams: LoginUserParams) => usingPost('/auth/login', new URLSearchParams(formParams), {})
export const authRegister = (userParams : RegisterUserParams) => usingPost('/auth/register', userParams)
export const authMe = () => usingGet ('/user/me')
export const authEdit = (newUserData:EditUserParams) => usingPut ('/user/me' , newUserData)
export const authRecover = (email:string) =>  usingPost('/user/password_recovery/', email)
export const authPasswordRecover = (recoverData:RecoverData) =>  usingPost('/user/reset_password/', recoverData)
export const authVerifyEmail = (data:TokenData) => usingPost('/user/verify_email/', data)
export const authUpdatePhoto = (obj:any) => usingPost('/user/update_photo/', obj)






