import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userLoggedOut } from 'core/session/slices/session'
import { ThaiDataState } from './types'
import {
  getAllProvinces,
  getAllThaiCovidData,
  getExactProvincesData,
} from '../../api/camerasService'

const initialState: ThaiDataState = {
  thaiData: {},
  provinces: {},
  exactProvince: {},
}

const covidSlice = createSlice({
  name: 'covid',
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(getThaiData.pending, state => {
        state.thaiData = { isLoading: true }
      })
      .addCase(getThaiData.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.thaiData = { isLoading: false, result: content, error }
      })

      .addCase(getProvinces.pending, state => {
        state.provinces = { isLoading: true }
      })
      .addCase(getProvinces.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.provinces = { isLoading: false, result: content, error }
      })

      .addCase(getExactProvince.pending, state => {
        state.exactProvince = { isLoading: true }
      })
      .addCase(getExactProvince.fulfilled, (state, action) => {
        const { content, error } = action.payload
        state.exactProvince = { isLoading: false, result: content, error }
      })

      // Clear store if 'userLoggedOut' happened
      .addCase(userLoggedOut, () => initialState)
  },
})

export const {} = covidSlice.actions
export default covidSlice.reducer

export const getThaiData = createAsyncThunk('covid/getThaiData', async (thai: any) => {
  const response = await getAllThaiCovidData(thai)
  return response
})
export const getProvinces = createAsyncThunk('covid/getProvinces', async () => {
  const response = await getAllProvinces()
  return response
})
export const getExactProvince = createAsyncThunk(
  'covid/getExactProvince',
  async (provinceData: any) => {
    const response = await getExactProvincesData(provinceData)
    return response
  }
)
