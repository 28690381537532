import { Suspense, lazy } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { PublicRoute } from 'routing/components'
import { Spinner } from 'components/common'
import routes from './routes'

export { routes }

export const LoginRoute = lazy(() => import(/* webpackChunkName: "Login" */ 'modules/Auth/pages/Login'))
export const RegistrationRoute = lazy(() => import(/* webpackChunkName: "RegistrationRoute" */ 'modules/Auth/pages/Registration'))
export const ForgotRoute = lazy(() => import(/* webpackChunkName: "ForgotRoute" */ 'modules/Auth/pages/ForgotPage'))
export const RecoverPasswordRoute = lazy(() => import(/* webpackChunkName: "RecoverPasswordRoute" */ 'modules/Auth/pages/RecoverPassword'))
export const VerifyEmailRoute = lazy(() => import(/* webpackChunkName: "VerifyEmailRoute" */ 'modules/Auth/pages/VerifyEmailPage'))

const AuthRouting = () => (
  <Suspense fallback={<Spinner />}>
    <Switch>
      <PublicRoute exact path={routes.login} component={LoginRoute} restricted />
      <PublicRoute exact path={routes.registration} component={RegistrationRoute} restricted />
      <PublicRoute exact path={routes.forgot} component={ForgotRoute} restricted />
      <PublicRoute exact path={routes.recoverPassword} component={RecoverPasswordRoute} restricted />
      <PublicRoute exact path={routes.verifyEmail} component={VerifyEmailRoute} restricted />

      <Redirect path='*' to={routes.login} />
    </Switch>
  </Suspense>
)

export default AuthRouting
